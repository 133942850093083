<template>
  <form-validation @submit="submitForm">
    <b-row>
      <b-col sm="12" md="6">
        <my-card :isloading="cardloading" title="Tambah Stok Opname">
          <template slot="body">
            <b-row>
              <b-col sm="12">
                <form-select :filter-id="idBranchArray" :clearable="false" ref="branchSelect" @value-changed="branch_name = $event.label" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col sm="12">
                <form-date :disabled="!cP(110)" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col sm="11">
                    <form-select :clearable="false" ref="typeSelect" url="v1/stock_opname_type_select2" @value-changed="name_type = $event.label" v-model="id_type" label="Tipe"></form-select>
                  </b-col>
                  <b-col sm="1">
                    <b-button @click="$refs.typeComponent.showModal()" class="mt-2" size="sm" variant="primary">+</b-button>
                    <c-type ref="typeComponent"></c-type>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <form-textarea :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
      <b-col sm="12">
        <my-card :isloading="cardloading" title="Detail Item">
          <template slot="body">
            <div>
              <b-row v-for="(tr,i) in details" :key="i">
                <b-col sm="12" md="3">
                  <form-select size="sm" :clearable="false" :ref="`itemSelect-${i}`" url="v1/item_select2" @value-changed="rowItemChange($event,i)" :rules="{required: true}" v-model="tr.id_item" :label="`Item ke ${i+1}`"></form-select>
                </b-col>
                <b-col sm="12" md="3">
                  <form-select size="sm" :clearable="false" :ref="`unitSelect-${i}`" url="v1/multi_unit_balance_select2" :queryparams="{id_item: tr.id_item, id_branch: id_branch}" @value-changed="rowUnitChange($event,i)" :rules="{required: true}" v-model="tr.id_unit" label="Stn"></form-select>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number disabled :rules="{ required: true }" size="sm" :value="tr.stock_real" label="Qty Sistem"></form-input-number>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true }" @keyup="calculateRowTotal(i)" size="sm" v-model="tr.qty" label="Qty Sekarang"></form-input-number>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true }" size="sm" @keyup="calculateRowTotal(i,false)" v-model="tr.stock_difference" label="Qty Selisih"></form-input-number>
                </b-col>
                <b-col sm="12" md="2">
                  <form-input size="sm" :rules="{ required: false }" v-model="tr.note" label="Catatan"></form-input>
                </b-col>
                <b-col>
                  <b-button v-if="i>0" class="mt-2" block variant="danger" @click="deleteRow(i)" size="sm">
                    <feather-icon icon="TrashIcon"></feather-icon> Hapus
                  </b-button>
                </b-col>
                <b-col sm="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col sm="12" md="2" class="mt-2" order-sm="1" order-md="0">
                <b-button block variant="primary" @click="addRow">
                  <feather-icon icon="PlusIcon"></feather-icon> Tambah Detail
                </b-button>
              </b-col>
              <b-col sm="12" offset-md="7" md="2">
                <b-row>
                  <b-col sm="12">
                    <form-input-number disabled :value="qtyPlus" label="Selisih +" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="qtyMin" label="Selisih -" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="qtyTotal" label="Selisih Total" :rules="{required: true}"></form-input-number>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="mt-4">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'stockopnames'}"></button-back>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
    </b-row>
  </form-validation>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import CType from './component/Type.vue'
import {BButton} from 'bootstrap-vue'
import _ from 'lodash'
import utils from './utils'

export default {
  components:{
    FormDate,
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    BButton,
    CType
  },
  data(){
    return {
      utils:utils,
      lodash: _,
      isloading: false,
      cardloading: false,
      id: this.$route.params.id||null,
      id_branch: null,
      branch_name: "",
      id_type: null,
      name_type: "",
      date: this.$moment().format(),
      note: "",
      details : []
    }
  },
  computed:{
    qtyMin(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        if(e.stock_difference>=0) continue
        total += parseFloat(e.stock_difference)
      }
      return Math.abs(total)
    },
    qtyPlus(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        if(e.stock_difference<0) continue
        total += parseFloat(e.stock_difference)
      }
      return total
    },
    qtyTotal(){
      return this.qtyPlus+this.qtyMin
    },
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      else return 0
    },
  },
  methods:{
    ...mapActions({
      dispatchStore: 'stockopnames/store',
      dispatchShow: 'stockopnames/show',
      dispatchUpdate: 'stockopnames/update',
    }),
    async getData(){
      this.cardloading = true
      let data = {}
      if(this.formStatus==1) {
        // edit
        data = await this.dispatchShow(this.id)
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        this.id_type = data.id_type
        this.name_type = data.name_type
        this.date = data.date
        this.note = data.note
        // this.details =
        let details = []
        for (let i = 0; i < data.stock_opname_details.length; i++) {
          const e = data.stock_opname_details[i];
          details.push(Object.assign({},e,{
            qty: e.stock_real+e.stock_difference
          }))
        }
        this.details = details
        this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
        this.$refs.typeSelect.initOption([{value:data.id_type,label:data.name_type}])
        for (let i = 0; i < this.details.length; i++) {
          const e = this.details[i];
          this.$nextTick(() => {
            this.$refs[`itemSelect-${i}`][0].initOption([{value:e.id_item,label:e.name_item}])
            this.$refs[`unitSelect-${i}`][0].initOption([{value:e.id_unit,label:e.name_unit}])
          })
        }
      } else {
        const defaults = this.$store.state.auth
        this.$refs.branchSelect.initOption(defaults.defaultbranches)
        this.id_branch = defaults.profile.id_branch
        this.branch_name = defaults.profile.branch_name
        this.addRow()
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,id_branch,branch_name,date,note,id_type,name_type,details} = this
      let params = {id,id_branch,branch_name,date,note,id_type,name_type,details}
      params.total = this.qtyTotal
      params.total_min = this.totalMin
      params.total_plus = this.totalPlus
      if(params.total==0) {
        this.isloading = false
        return this.toastWarning("Maaf", "Jumlah Grand Total yang anda masukkan 0!")
      }
      if(this.formStatus==0) delete params.id
      try {
        if(this.formStatus==0) await this.dispatchStore(params)
        else await this.dispatchUpdate(params)
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'stockopnames'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    addRow(){
      const data = {
        id_item: null,
        name_item: "",
        id_unit: null,
        name_unit: "",
        note: "",
        stock_real: 0,
        stock_difference: 0,
        qty: 0
      }
      this.details.push(data)
    },
    deleteRow(row){
      this.details.splice(row,1)
    },
    rowItemChange(e,row){
      let tr = this.details[row]
      tr.name_item = e.label
      tr.id_unit = null
      tr.qty = 0
      tr.stock_real = 0
      this.calculateRowTotal(row)
    },
    rowUnitChange(e,row){
      let tr = this.details[row]
      tr.name_unit = e.label
      tr.qty = e.balance ? e.balance.toFixed(2) : e.balance
      tr.stock_real = e.balance ? e.balance.toFixed(2) : e.balance
      this.calculateRowTotal(row)
    },
    calculateRowTotal(row,isQty=true){
      const {qty, stock_real, stock_difference} = this.details[row]
      if(isQty) this.details[row].stock_difference = (qty-stock_real).toFixed(2)
      else this.details[row].qty = parseFloat(stock_difference)+parseFloat(stock_real)
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>