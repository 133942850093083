<template>
  <b-modal ref="typeModal" hide-footer title="Tipe Stok Opname">
    <form-validation @submit="submitForm">
      <form-input v-model="name" label="Nama" :rules="{required: true}"></form-input>

      <b-button type="submit" variant="primary" class="mt-3"><feather-icon icon="SaveIcon"></feather-icon> Simpan</b-button>
    </form-validation>
  </b-modal>
</template>

<script>
import FormValidation from '@/my-components/FormValidation.vue'
import FormInput from '@/my-components/FormInput.vue'
import {BModal,BButton} from 'bootstrap-vue'

export default {
  components:{
    BModal,
    BButton,
    FormValidation,
    FormInput
  },
  data(){
    return {
      name:""
    }
  },
  methods:{
    showModal(){
      this.name = ""
      this.$refs.typeModal.show()
    },
    async submitForm(){
      try {
        await this.$http.post(`v1/stock_opname_type_add`,{name:this.name})
        this.toastSuccess("OK","Data Berhasil Disimpan")
        this.$refs.typeModal.hide()
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style>

</style>